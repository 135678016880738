export const HSRPFHisto = [
  {
    slug: 'acheron',
    score: 30699,
    usage: 52.93,
    role: 'dps'
  },
  {
    slug: 'argenti',
    score: 34287,
    usage: 11.89,
    role: 'dps'
  },
  {
    slug: 'arlan',
    score: 22000,
    usage: 0,
    role: 'dps'
  },
  {
    slug: 'asta',
    score: 29510,
    usage: 1.94,
    role: 'amplifier'
  },
  {
    slug: 'aventurine',
    score: 32608,
    usage: 39.45,
    role: 'sustain'
  },
  {
    slug: 'bailu',
    score: 30002,
    usage: 1.18,
    role: 'sustain'
  },
  {
    slug: 'black-swan',
    score: 31002,
    usage: 26.64,
    role: 'specialist'
  },
  {
    slug: 'blade',
    score: 31678,
    usage: 3.05,
    role: 'dps'
  },
  {
    slug: 'boothill',
    score: 22000,
    usage: 0.28,
    role: 'specialist'
  },
  {
    slug: 'bronya',
    score: 31445,
    usage: 8.52,
    role: 'amplifier'
  },
  {
    slug: 'clara',
    score: 31841,
    usage: 15.49,
    role: 'dps'
  },
  {
    slug: 'dan-heng',
    score: 22000,
    usage: 0.01,
    role: 'dps'
  },
  {
    slug: 'dr-ratio',
    score: 24863,
    usage: 2.05,
    role: 'dps'
  },
  {
    slug: 'feixiao',
    score: 30866,
    usage: 14.62,
    role: 'dps',
    new: true
  },
  {
    slug: 'firefly',
    score: 29323,
    usage: 36.85,
    role: 'specialist'
  },
  {
    slug: 'fu-xuan',
    score: 32784,
    usage: 42.96,
    role: 'sustain'
  },
  {
    slug: 'gallagher',
    score: 31375,
    usage: 46.7,
    role: 'sustain'
  },
  {
    slug: 'gepard',
    score: 29606,
    usage: 3.24,
    role: 'sustain'
  },
  {
    slug: 'guinaifen',
    score: 23266,
    usage: 1.8,
    role: 'amplifier'
  },
  {
    slug: 'hanya',
    score: 30865,
    usage: 0.75,
    role: 'amplifier'
  },
  {
    slug: 'herta',
    score: 34309,
    usage: 55.55,
    role: 'dps'
  },
  {
    slug: 'himeko',
    score: 32378,
    usage: 51.53,
    role: 'dps'
  },
  {
    slug: 'hook',
    score: 22000,
    usage: 0,
    role: 'dps'
  },
  {
    slug: 'huohuo',
    score: 33532,
    usage: 24.66,
    role: 'sustain'
  },
  {
    slug: 'imbibitor-lunae',
    score: 22693,
    usage: 2.44,
    role: 'dps'
  },
  {
    slug: 'jade',
    score: 37389,
    usage: 20.96,
    role: 'specialist'
  },
  {
    slug: 'jiaoqiu',
    score: 34184,
    usage: 20.99,
    role: 'amplifier',
    new: true
  },
  {
    slug: 'jing-yuan',
    score: 27363,
    usage: 5.49,
    role: 'dps'
  },
  {
    slug: 'jingliu',
    score: 26807,
    usage: 1.23,
    role: 'dps'
  },
  {
    slug: 'kafka',
    score: 31205,
    usage: 30.96,
    role: 'specialist'
  },
  {
    slug: 'lingsha',
    score: 36985,
    usage: 10.01,
    role: 'sustain',
    new: true
  },
  {
    slug: 'luka',
    score: 22000,
    usage: 0.01,
    role: 'specialist'
  },
  {
    slug: 'luocha',
    score: 31622,
    usage: 12.72,
    role: 'sustain'
  },
  {
    slug: 'lynx',
    score: 30739,
    usage: 2.09,
    role: 'sustain'
  },
  {
    slug: 'march-7th',
    score: 31704,
    usage: 0.65,
    role: 'sustain'
  },
  {
    slug: 'march-7th-swordmaster',
    score: 31249,
    usage: 4.83,
    role: 'specialist',
    new: true
  },
  {
    slug: 'misha',
    score: 22000,
    usage: 0.01,
    role: 'dps'
  },
  {
    slug: 'moze',
    score: 22000,
    usage: 0.19,
    role: 'specialist',
    new: true
  },
  {
    slug: 'natasha',
    score: 26960,
    usage: 0.14,
    role: 'sustain'
  },
  {
    slug: 'pela',
    score: 30207,
    usage: 19.61,
    role: 'amplifier'
  },
  {
    slug: 'qingque',
    score: 24431,
    usage: 0.25,
    role: 'dps'
  },
  {
    slug: 'robin',
    score: 34416,
    usage: 46.2,
    role: 'amplifier'
  },
  {
    slug: 'ruan-mei',
    score: 32490,
    usage: 78.68,
    role: 'amplifier'
  },
  {
    slug: 'sampo',
    score: 23613,
    usage: 0.2,
    role: 'specialist'
  },
  {
    slug: 'seele',
    score: 30273,
    usage: 2.48,
    role: 'dps'
  },
  {
    slug: 'serval',
    score: 24745,
    usage: 1.08,
    role: 'dps'
  },
  {
    slug: 'silver-wolf',
    score: 27153,
    usage: 5.0,
    role: 'amplifier'
  },
  {
    slug: 'sparkle',
    score: 32412,
    usage: 35.02,
    role: 'amplifier'
  },
  {
    slug: 'sushang',
    score: 22000,
    usage: 0.04,
    role: 'specialist'
  },
  {
    slug: 'tingyun',
    score: 32743,
    usage: 22.18,
    role: 'amplifier'
  },
  {
    slug: 'topaz',
    score: 30354,
    usage: 9.07,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-fire',
    score: 31211,
    usage: 0.51,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-imaginary',
    score: 28406,
    usage: 36.62,
    role: 'amplifier'
  },
  {
    slug: 'trailblazer-physical',
    score: 22000,
    usage: 0.01,
    role: 'dps'
  },
  {
    slug: 'welt',
    score: 23339,
    usage: 0.69,
    role: 'specialist'
  },
  {
    slug: 'xueyi',
    score: 29739,
    usage: 1.84,
    role: 'specialist'
  },
  {
    slug: 'yanqing',
    score: 22000,
    usage: 0,
    role: 'dps'
  },
  {
    slug: 'yukong',
    score: 22000,
    usage: 0.1,
    role: 'amplifier'
  },
  {
    slug: 'yunli',
    score: 34857,
    usage: 15.98,
    role: 'dps',
    new: true
  }
];
